import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/SEO/index"
import IndexInpatient from "../components/Sections/IndexInpatient"
import NavBar from "../components/navbar"

import "./mystyles.scss"

const HospitalPage = ({ data }) => {
  return (
    <>
      <NavBar />
      <SEO title="Fanomed clinics" description="Fanomed clinics - Стационар" />
      <IndexInpatient data={data.section} dataHospital={data.hospital} />
    </>
  )
}

export const HospitalPageQuery = graphql`
  query {
    section: allIndexsectionsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    hospital: allHospitalYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(HospitalPage)
